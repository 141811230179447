import React from "react"
import { useTranslation } from "react-i18next"
import { Grid, Container, Box, Divider, Paper } from "@material-ui/core"
import "../styles/general.css"

import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Collapse from "@material-ui/core/Collapse"
import Avatar from "@material-ui/core/Avatar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import { red } from "@material-ui/core/colors"
import FavoriteIcon from "@material-ui/icons/Favorite"
import ShareIcon from "@material-ui/icons/Share"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import MoreVertIcon from "@material-ui/icons/MoreVert"

import ImgUnisanmartinH from "./imgComponents/imgUnisanmartinH"
import ImgHospitalKennedy from "./imgComponents/imgHospitalKennedy"
import ImgHospitalTunal from "./imgComponents/imgHospitalTunal"
import ImgHospitalSanBlas from "./imgComponents/imgHospitalSanBlas"
import ImgHospitalSanRafaelFaca from "./imgComponents/imgHospitalSanRafaelFaca"
import ImgHospitalSamaritanaZipa from "./imgComponents/imgHospitalSamaritanaZipa"
import ImgHospitalTunjuelito from "./imgComponents/imgHospitalTunjuelito"
import ImgSecretariaSalud from "./imgComponents/imgSecretariaSalud"
import ImgHospitalSanRafaelFusa from "./imgComponents/imgHospitalSanRafaelFusa"

const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}))

const ExperienceUndergraduateComponent = () => {
  const { t, i18n } = useTranslation()

  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <div>
      <Box mt={1}></Box>
      <h2 className="titlesGradientH2">{t("experience.undergraduatetitle")}</h2>
      <Divider />
      <ImgUnisanmartinH />
      <p>
        <b>
          {t("university.sanmartin")} - {t("profile.generalpractitioner")}
        </b>
        <br />
        Bogotá - Colombia, 2012 - 2017
        <Divider />
      </p>
      <h2 className="titlesGradientH2">2017</h2>
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgHospitalSanRafaelFusa />}
          title={t("hospital.sanrafaelfusa")}
          subheader={t("experience.2017.subheader02")}
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("experience.rotation.specialinternship")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgHospitalSanRafaelFusa />}
          title={t("hospital.sanrafaelfusa")}
          subheader={t("experience.2017.subheader01")}
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("experience.rotation.medicalinternship")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <h2 className="titlesGradientH2">2016</h2>
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgHospitalTunjuelito />}
          title={t("hospital.tunjuelitocarmen")}
          subheader="Bogotá - Colombia, 2016"
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("experience.rotation.gynecology")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgHospitalKennedy />}
          title={t("hospital.kennedy")}
          subheader="Bogotá - Colombia, 2016"
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("experience.rotation.pediatrics")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgSecretariaSalud />}
          title={t("hospital.tintal")}
          subheader="Bogotá - Colombia, 2016"
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("experience.rotation.pediatrics")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <h2 className="titlesGradientH2">2015</h2>
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgHospitalKennedy />}
          title={t("hospital.kennedy")}
          subheader="Bogotá - Colombia, 2015"
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("experience.rotation.gsoa")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgHospitalTunal />}
          title={t("hospital.tunal")}
          subheader="Bogotá - Colombia, 2015"
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("experience.rotation.ophthalmology")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgHospitalSanBlas />}
          title={t("hospital.sanblas")}
          subheader="Bogotá - Colombia, 2015"
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("experience.rotation.clinicalpsychiatry")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgHospitalSanRafaelFaca />}
          title={t("hospital.sanrafaelfaca")}
          subheader="Facatativá - Colombia, 2015"
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("experience.rotation.ou")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgHospitalSamaritanaZipa />}
          title={t("hospital.samaritanazipa")}
          subheader="Zipaquirá - Colombia, 2015"
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("experience.rotation.plasticsurgery")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <h2 className="titlesGradientH2">2014</h2>
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgHospitalKennedy />}
          title={t("hospital.kennedy")}
          subheader="Bogotá - Colombia, 2014"
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("experience.rotation.internalmedicine")}
          </Typography>
        </CardContent>
      </Card>
      <br />
    </div>
  )
}

export default ExperienceUndergraduateComponent
