import React from "react"
import { useTranslation } from "react-i18next"
import { Container, Typography, Box, Button } from "@material-ui/core"
import "../styles/general.css"
import Footer from "./Footer"
import { Link } from "gatsby"
import ExperienceUndergraduateComponent from "./ExperienceUndergraduateComponent"
import ExperienceButtonsComponent from "./ExperienceButtonsComponent"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}))

const UndergraduateComponent = () => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  return (
    <div className="componentsDiv">
      <Box align="justify">
        <h1 className="titlesGradientH1">{t("title.experience")}</h1>
        <ExperienceButtonsComponent />
        <ExperienceUndergraduateComponent />
      </Box>
      <br />
      <Box>
        <Footer />
      </Box>
    </div>
  )
}

export default UndergraduateComponent
